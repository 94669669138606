
import ApiService from "@/core/services/ApiService"
import { useRoute, useRouter } from "vue-router"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import CorporationComponent from "@/components/admin/definitions/corporation/corporation.vue"
import { Corporation } from "@/core/models/Corporation"
import swalConfirm from "@/core/helpers/swal-confirm"
import { SelectModel } from "@/core/models/SelectModel"
export default defineComponent({
  name: "admin-corporation-list",
  components: {
    CorporationComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const corporationList = ref<Corporation[]>([])
    const filterOnlyActives = ref(true)
    const newCorporation = ref(false)
    const activeCorporationId = ref<string | undefined>()
    const accountList = ref<SelectModel[]>([])
    const activeAccountId = ref<string | undefined>()

    watch(
      () => route.params.corporation_id,
      newValue => {
        activeCorporationId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newCorporation.value = true == newValue
      }
    )

    const onAccountChange = async () => {
      await getCorporationList(activeAccountId.value)
    }

    const getAccountList = async () => {
      const { data } = await ApiService.get("account")
      accountList.value = data.map(x => ({ name: x.title, id: x.id }))
    }

    const corporationListFiltered = computed(() => {
      return corporationList.value.filter(x => {
        return x["isActive"] == true || !filterOnlyActives.value
      })
    })

    const getCorporationList = async id => {
      if (id != undefined) {
        const { data } = await ApiService.get(`account/${id}/corporation`)
        corporationList.value = data
      }
    }

    const removeCorporation = async corporationId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("corporation/remove/" + corporationId)
        await getCorporationList(undefined)
      }
    }

    const handleCorporationDrawerClose = () => {
      router.push({ name: "admin-corporation-list" })
    }

    onMounted(async () => {
      activeCorporationId.value = route.params.corporation_id as string
      newCorporation.value = props.add
      await getAccountList()

      //await getCorporationList()
    })

    return {
      corporationList,
      Edit,
      filterOnlyActives,
      corporationListFiltered,
      removeCorporation,
      getCorporationList,
      newCorporation,
      activeCorporationId,
      handleCorporationDrawerClose,
      getAccountList,
      accountList,
      activeAccountId,
      onAccountChange,
    }
  },
})
