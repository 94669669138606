
import { Corporation } from "@/core/models/Corporation"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { ElForm } from "element-plus"
import { SelectModel } from "@/core/models/SelectModel"

export default defineComponent({
  name: "admin-corporation-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const corporationData = ref<Corporation>({} as Corporation)
    const accountList = ref<SelectModel[]>([])

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const getCorporation = async id => {
      const { data } = await ApiService.get("corporation/" + id)
      corporationData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getCorporation(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          corporationData.value = {
            isActive: true,
          } as Corporation
        }
      }
    )

    const rules = ref({
      title: getRule(RuleTypes.TEXT100, "Şirket"),
      shortName: getRule(RuleTypes.TEXT50, "Kısa Ad"),
      officialTitle: getRule(RuleTypes.TEXT100, "Resmi ünvan"),
      taxNumber: getRule(RuleTypes.NUMERIC, "Vergi Numarası"),
      taxDepartment: getRule(RuleTypes.TEXT50, "Vergi Dairesi"),
      tradeRegistryNumber: getRule(RuleTypes.NUMERIC, "Ticaret Sicil Numarası"),
      mersisNumber: getRule(RuleTypes.MERSISNO),
    })

    async function addCorporation() {
      const data = { ...corporationData.value }
      await ApiService.post("corporation/add", data)
      emit("getlist")
      router.push({ name: "admin-corporation-list" })
    }
    async function updateCorporation() {
      const data = { ...corporationData.value, id: props.id }
      await ApiService.post("corporation/edit", data)
      emit("getlist")
      router.push({ name: "admin-corporation-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addCorporation()
          } else {
            updateCorporation()
          }
        }
      })
    }

    const getAccountList = async () => {
      const { data } = await ApiService.get("account")
      accountList.value = data.map(x => ({ name: x.title, id: x.id }))
    }

    onMounted(async () => {
      await getAccountList()
    })

    return {
      showDrawer,
      newCorporation: add,
      activeCorporationId: id,
      handleClose: props.closed,
      corporationData,
      addOrUpdate,
      ruleFormRef,
      rules,
      accountList,
    }
  },
})
